import { BehaviorSubject, Observable } from 'rxjs'
import { EventEmitter, Injectable, Output } from '@angular/core'
import { LocalStorageService } from '@services/local-storage-service'

export type ToolbarPlacement = 'top' | 'side'

@Injectable({
    providedIn: 'root',
})
export class ToolbarService {
    private toolbarPlacementSubject: BehaviorSubject<ToolbarPlacement>
    toolbarPlacement$: Observable<ToolbarPlacement>
    @Output() openMenuRequested = new EventEmitter<void>()

    constructor(
        private localStorageService: LocalStorageService
    ) {
        this.toolbarPlacementSubject = new BehaviorSubject<ToolbarPlacement>(
            this.getToolbarPlacement()
        )
        this.toolbarPlacement$ = this.toolbarPlacementSubject.asObservable()
    }

    openMenu() {
        this.openMenuRequested.emit()
    }

    getToolbarPlacement(): ToolbarPlacement {
        const placement = this.localStorageService.getItem('toolbarPlacement') as string | null
        switch (placement) {
        case 'top':
        case 'side':
            return placement
        default:
            return 'top'
        }
    }

    setToolbarPlacement(placement: ToolbarPlacement) {
        this.localStorageService.setItem('toolbarPlacement', placement)
        this.toolbarPlacementSubject.next(placement)
    }
}
